<template>
  <div>
    <q-table
      :columns="tableColumns"
      :rows-per-page-options="[20, 40, 80]"
      :data="requisites"
      title="Requisites"
    >
      <template #header="props">
        <q-tr :props="props">
          <q-th auto-width />

          <q-th v-for="col in props.cols" :key="col.name" :props="props">
            {{ col.label }}
          </q-th>
        </q-tr>
      </template>

      <template #body="props">
        <q-tr :props="props">
          <q-td auto-width>
            <q-btn
              :icon="!props.expand ? 'mdi-chevron-down' : 'mdi-chevron-up'"
              outline
              unelevated
              round
              dense
              size="sm"
              color="primary"
              @click="props.expand = !props.expand"
            />
          </q-td>

          <q-td v-for="col in props.cols" :key="col.name" :props="props">
            <DSwitch
              v-if="col.name === 'enable'"
              :value="props.row.enable"
              :disable="!accessEnableRequisites && !accessDisableRequisites"
              :disable-true-value="!accessDisableRequisites"
              :disable-false-value="!accessEnableRequisites"
              dense
              color="green"
              @input="
                toggleRefillAccountRequisiteEnable({
                  enable: $event,
                  id: props.row.id,
                })
              "
            />

            <template v-else-if="col.name === 'properties'">
              <q-card
                v-for="(item, itemIndex) in props.row.properties"
                :key="itemIndex"
              >
                <q-card-section>
                  <p class="text-weight-bold q-mb-sm">Key</p>
                  <q-input
                    :value="item.key"
                    readonly
                    outlined
                    dense
                    class="q-mb-md"
                  />

                  <p class="text-weight-bold q-mb-sm">Value</p>
                  <q-input :value="item.value" readonly outlined dense />
                </q-card-section>
              </q-card>
            </template>

            <template v-else-if="col.name === 'payload'">
              <q-card
                v-for="(item, itemIndex) in props.row.payload"
                :key="itemIndex"
              >
                <q-card-section>
                  <p class="text-weight-bold q-mb-sm">Key</p>
                  <q-input
                    :value="item.key"
                    readonly
                    outlined
                    dense
                    class="q-mb-md"
                  />

                  <p class="text-weight-bold q-mb-sm">Value</p>
                  <q-input :value="item.value" readonly outlined dense />
                </q-card-section>
              </q-card>
            </template>

            <div
              v-else-if="col.name === 'actions'"
              class="flex inline items-center"
            >
              <q-btn
                unelevated
                round
                color="red"
                size="xs"
                icon="mdi-delete"
                @click="
                  toggleConfirmDeleteDialog({
                    showed: true,
                    id: props.row.id,
                  })
                "
              />

              <q-btn
                unelevated
                round
                color="primary"
                size="xs"
                icon="mdi-pencil"
                class="q-ml-sm"
                @click="
                  toggleRequisiteFormDialog({
                    showed: true,
                    data: props.row,
                  })
                "
              />
            </div>

            <template v-else>
              {{ col.value }}
            </template>
          </q-td>
        </q-tr>

        <q-tr v-if="props.expand" :props="props">
          <q-td colspan="100%">
            <p
              class="text-subtitle2 q-mb-xs q-mt-none text-red text-weight-bold"
            >
              Response Requisite
            </p>

            <q-input
              v-model="props.row.response"
              readonly
              outlined
              dense
              autogrow
              type="textarea"
            />
          </q-td>
        </q-tr>
      </template>
    </q-table>

    <q-dialog
      v-if="confirmDeleteDialog.showed"
      v-model="confirmDeleteDialog.showed"
      persistent
    >
      <q-card class="full-width" :style="{ maxWidth: '400px' }">
        <q-card-section>
          <h3 class="text-h6 text-center q-mt-none">Are you sure?</h3>

          <div class="row q-mt-md">
            <section class="col-6">
              <div class="q-pr-sm">
                <q-btn
                  no-caps
                  unelevated
                  outline
                  type="button"
                  color="red"
                  label="Cancel"
                  class="full-width"
                  @click="
                    toggleConfirmDeleteDialog({
                      showed: false,
                    })
                  "
                />
              </div>
            </section>

            <section class="col-6">
              <div class="q-pl-sm">
                <q-btn
                  no-caps
                  unelevated
                  type="submit"
                  color="primary"
                  label="Ok"
                  class="full-width"
                  @click="deleteRequisite(confirmDeleteDialog.id)"
                />
              </div>
            </section>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog
      v-if="requisiteFormDialog.showed"
      v-model="requisiteFormDialog.showed"
      persistent
    >
      <RequisiteForm
        :account-id="accountId"
        :initial-data="requisiteFormDialog.data"
        @created="onCreatedRequisite"
        @updated="onUpdatedRequisite"
        @cancel="
          toggleRequisiteFormDialog({
            showed: false,
          })
        "
      />
    </q-dialog>
  </div>
</template>

<script>
import { DSwitch } from '@/features/switch';
import { accountController } from '@/shared/api';
import { ROLES } from '@/shared/constants';
import { authUtils } from '@/shared/utils';

export default {
  props: {
    accountId: {
      type: Number,
      required: true,
    },
    requisites: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      confirmDeleteDialog: {
        showed: false,
        id: 0,
      },
      requisiteFormDialog: {
        showed: false,
        data: null,
      },
    };
  },
  components: {
    DSwitch,
    RequisiteForm: () => import('./requisite-form'),
  },
  computed: {
    accessActionsRequisites() {
      return authUtils.checkRoles([
        ROLES.ROLE_ADMIN,
        ROLES.ROLE_SUPERVISOR,
        ROLES.ROLE_AGENT,
      ]);
    },
    accessEnableRequisites() {
      return authUtils.checkRoles([ROLES.ROLE_ADMIN, ROLES.ROLE_SUPERVISOR]);
    },
    accessDisableRequisites() {
      return authUtils.checkRoles([
        ROLES.ROLE_ADMIN,
        ROLES.ROLE_SUPERVISOR,
        ROLES.ROLE_AGENT,
      ]);
    },
    tableColumns() {
      return [
        {
          name: 'id',
          field: 'id',
          label: 'ID',
          align: 'left',
        },
        {
          field: 'properties',
          name: 'properties',
          label: 'Properties',
          align: 'left',
        },
        {
          field: 'payload',
          name: 'payload',
          label: 'Payload',
          align: 'left',
        },
        {
          field: 'enable',
          name: 'enable',
          label: 'Enable',
          align: 'left',
        },
        this.accessActionsRequisites && {
          name: 'actions',
          label: '',
          align: 'right',
        },
      ].filter(Boolean);
    },
  },
  methods: {
    toggleRequisiteFormDialog({ showed, data = null }) {
      this.requisiteFormDialog.showed = showed;
      this.requisiteFormDialog.data = window.structuredClone(data);
    },
    toggleConfirmDeleteDialog({ showed, id = 0 }) {
      this.confirmDeleteDialog.showed = showed;
      this.confirmDeleteDialog.id = id;
    },
    toggleRefillAccountRequisiteEnable({ id, enable }) {
      accountController.updateRefillAccountRequisiteEnable({ id, enable });
    },
    deleteRequisite(id) {
      accountController.deleteRefillAccountRequisite({ id });
      this.$emit('deleted:requisite', id);
      this.toggleConfirmDeleteDialog({
        showed: false,
      });
    },
    onCreatedRequisite() {
      this.toggleRequisiteFormDialog(false);
      this.$emit('created:requisite');
    },
    onUpdatedRequisite() {
      this.toggleRequisiteFormDialog(false);
      this.$emit('updated:requisite');
    },
  },
};
</script>
