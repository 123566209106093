var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('q-table',{attrs:{"columns":_vm.tableColumns,"rows-per-page-options":[20, 40, 80],"data":_vm.requisites,"title":"Requisites"},scopedSlots:_vm._u([{key:"header",fn:function(props){return [_c('q-tr',{attrs:{"props":props}},[_c('q-th',{attrs:{"auto-width":""}}),_vm._l((props.cols),function(col){return _c('q-th',{key:col.name,attrs:{"props":props}},[_vm._v(" "+_vm._s(col.label)+" ")])})],2)]}},{key:"body",fn:function(props){return [_c('q-tr',{attrs:{"props":props}},[_c('q-td',{attrs:{"auto-width":""}},[_c('q-btn',{attrs:{"icon":!props.expand ? 'mdi-chevron-down' : 'mdi-chevron-up',"outline":"","unelevated":"","round":"","dense":"","size":"sm","color":"primary"},on:{"click":function($event){props.expand = !props.expand}}})],1),_vm._l((props.cols),function(col){return _c('q-td',{key:col.name,attrs:{"props":props}},[(col.name === 'enable')?_c('DSwitch',{attrs:{"value":props.row.enable,"disable":!_vm.accessEnableRequisites && !_vm.accessDisableRequisites,"disable-true-value":!_vm.accessDisableRequisites,"disable-false-value":!_vm.accessEnableRequisites,"dense":"","color":"green"},on:{"input":function($event){return _vm.toggleRefillAccountRequisiteEnable({
                enable: $event,
                id: props.row.id,
              })}}}):(col.name === 'properties')?_vm._l((props.row.properties),function(item,itemIndex){return _c('q-card',{key:itemIndex},[_c('q-card-section',[_c('p',{staticClass:"text-weight-bold q-mb-sm"},[_vm._v("Key")]),_c('q-input',{staticClass:"q-mb-md",attrs:{"value":item.key,"readonly":"","outlined":"","dense":""}}),_c('p',{staticClass:"text-weight-bold q-mb-sm"},[_vm._v("Value")]),_c('q-input',{attrs:{"value":item.value,"readonly":"","outlined":"","dense":""}})],1)],1)}):(col.name === 'payload')?_vm._l((props.row.payload),function(item,itemIndex){return _c('q-card',{key:itemIndex},[_c('q-card-section',[_c('p',{staticClass:"text-weight-bold q-mb-sm"},[_vm._v("Key")]),_c('q-input',{staticClass:"q-mb-md",attrs:{"value":item.key,"readonly":"","outlined":"","dense":""}}),_c('p',{staticClass:"text-weight-bold q-mb-sm"},[_vm._v("Value")]),_c('q-input',{attrs:{"value":item.value,"readonly":"","outlined":"","dense":""}})],1)],1)}):(col.name === 'actions')?_c('div',{staticClass:"flex inline items-center"},[_c('q-btn',{attrs:{"unelevated":"","round":"","color":"red","size":"xs","icon":"mdi-delete"},on:{"click":function($event){return _vm.toggleConfirmDeleteDialog({
                  showed: true,
                  id: props.row.id,
                })}}}),_c('q-btn',{staticClass:"q-ml-sm",attrs:{"unelevated":"","round":"","color":"primary","size":"xs","icon":"mdi-pencil"},on:{"click":function($event){return _vm.toggleRequisiteFormDialog({
                  showed: true,
                  data: props.row,
                })}}})],1):[_vm._v(" "+_vm._s(col.value)+" ")]],2)})],2),(props.expand)?_c('q-tr',{attrs:{"props":props}},[_c('q-td',{attrs:{"colspan":"100%"}},[_c('p',{staticClass:"text-subtitle2 q-mb-xs q-mt-none text-red text-weight-bold"},[_vm._v(" Response Requisite ")]),_c('q-input',{attrs:{"readonly":"","outlined":"","dense":"","autogrow":"","type":"textarea"},model:{value:(props.row.response),callback:function ($$v) {_vm.$set(props.row, "response", $$v)},expression:"props.row.response"}})],1)],1):_vm._e()]}}])}),(_vm.confirmDeleteDialog.showed)?_c('q-dialog',{attrs:{"persistent":""},model:{value:(_vm.confirmDeleteDialog.showed),callback:function ($$v) {_vm.$set(_vm.confirmDeleteDialog, "showed", $$v)},expression:"confirmDeleteDialog.showed"}},[_c('q-card',{staticClass:"full-width",style:({ maxWidth: '400px' })},[_c('q-card-section',[_c('h3',{staticClass:"text-h6 text-center q-mt-none"},[_vm._v("Are you sure?")]),_c('div',{staticClass:"row q-mt-md"},[_c('section',{staticClass:"col-6"},[_c('div',{staticClass:"q-pr-sm"},[_c('q-btn',{staticClass:"full-width",attrs:{"no-caps":"","unelevated":"","outline":"","type":"button","color":"red","label":"Cancel"},on:{"click":function($event){return _vm.toggleConfirmDeleteDialog({
                    showed: false,
                  })}}})],1)]),_c('section',{staticClass:"col-6"},[_c('div',{staticClass:"q-pl-sm"},[_c('q-btn',{staticClass:"full-width",attrs:{"no-caps":"","unelevated":"","type":"submit","color":"primary","label":"Ok"},on:{"click":function($event){return _vm.deleteRequisite(_vm.confirmDeleteDialog.id)}}})],1)])])])],1)],1):_vm._e(),(_vm.requisiteFormDialog.showed)?_c('q-dialog',{attrs:{"persistent":""},model:{value:(_vm.requisiteFormDialog.showed),callback:function ($$v) {_vm.$set(_vm.requisiteFormDialog, "showed", $$v)},expression:"requisiteFormDialog.showed"}},[_c('RequisiteForm',{attrs:{"account-id":_vm.accountId,"initial-data":_vm.requisiteFormDialog.data},on:{"created":_vm.onCreatedRequisite,"updated":_vm.onUpdatedRequisite,"cancel":function($event){return _vm.toggleRequisiteFormDialog({
          showed: false,
        })}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }